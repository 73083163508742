exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ageline-js": () => import("./../../../src/pages/ageline.js" /* webpackChunkName: "component---src-pages-ageline-js" */),
  "component---src-pages-ai-ml-software-development-js": () => import("./../../../src/pages/ai-ml-software-development.js" /* webpackChunkName: "component---src-pages-ai-ml-software-development-js" */),
  "component---src-pages-blue-manor-academy-js": () => import("./../../../src/pages/blue-manor-academy.js" /* webpackChunkName: "component---src-pages-blue-manor-academy-js" */),
  "component---src-pages-callreview-js": () => import("./../../../src/pages/callreview.js" /* webpackChunkName: "component---src-pages-callreview-js" */),
  "component---src-pages-casadesk-js": () => import("./../../../src/pages/casadesk.js" /* webpackChunkName: "component---src-pages-casadesk-js" */),
  "component---src-pages-cheqmate-js": () => import("./../../../src/pages/cheqmate.js" /* webpackChunkName: "component---src-pages-cheqmate-js" */),
  "component---src-pages-cloud-infrastructure-management-js": () => import("./../../../src/pages/cloud-infrastructure-management.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-management-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dedicated-team-java-js": () => import("./../../../src/pages/dedicated-team-java.js" /* webpackChunkName: "component---src-pages-dedicated-team-java-js" */),
  "component---src-pages-dedicated-team-js": () => import("./../../../src/pages/dedicated-team.js" /* webpackChunkName: "component---src-pages-dedicated-team-js" */),
  "component---src-pages-dedicated-team-php-js": () => import("./../../../src/pages/dedicated-team-php.js" /* webpackChunkName: "component---src-pages-dedicated-team-php-js" */),
  "component---src-pages-dedicated-team-python-js": () => import("./../../../src/pages/dedicated-team-python.js" /* webpackChunkName: "component---src-pages-dedicated-team-python-js" */),
  "component---src-pages-dedicated-team-react-js": () => import("./../../../src/pages/dedicated-team-react.js" /* webpackChunkName: "component---src-pages-dedicated-team-react-js" */),
  "component---src-pages-dope-360-js": () => import("./../../../src/pages/dope360.js" /* webpackChunkName: "component---src-pages-dope-360-js" */),
  "component---src-pages-euronavigator-js": () => import("./../../../src/pages/euronavigator.js" /* webpackChunkName: "component---src-pages-euronavigator-js" */),
  "component---src-pages-fitness-sportech-js": () => import("./../../../src/pages/fitness-sportech.js" /* webpackChunkName: "component---src-pages-fitness-sportech-js" */),
  "component---src-pages-hr-software-development-js": () => import("./../../../src/pages/hr-software-development.js" /* webpackChunkName: "component---src-pages-hr-software-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurtech-software-js": () => import("./../../../src/pages/insurtech-software.js" /* webpackChunkName: "component---src-pages-insurtech-software-js" */),
  "component---src-pages-kamsa-js": () => import("./../../../src/pages/kamsa.js" /* webpackChunkName: "component---src-pages-kamsa-js" */),
  "component---src-pages-martech-software-js": () => import("./../../../src/pages/martech-software.js" /* webpackChunkName: "component---src-pages-martech-software-js" */),
  "component---src-pages-minutes-depot-js": () => import("./../../../src/pages/minutes_depot.js" /* webpackChunkName: "component---src-pages-minutes-depot-js" */),
  "component---src-pages-mobile-development-js": () => import("./../../../src/pages/mobile-development.js" /* webpackChunkName: "component---src-pages-mobile-development-js" */),
  "component---src-pages-mvp-development-js": () => import("./../../../src/pages/mvp-development.js" /* webpackChunkName: "component---src-pages-mvp-development-js" */),
  "component---src-pages-mvp-developments-js": () => import("./../../../src/pages/mvp-developments.js" /* webpackChunkName: "component---src-pages-mvp-developments-js" */),
  "component---src-pages-outsail-js": () => import("./../../../src/pages/outsail.js" /* webpackChunkName: "component---src-pages-outsail-js" */),
  "component---src-pages-product-consulting-js": () => import("./../../../src/pages/product-consulting.js" /* webpackChunkName: "component---src-pages-product-consulting-js" */),
  "component---src-pages-product-design-js": () => import("./../../../src/pages/product-design.js" /* webpackChunkName: "component---src-pages-product-design-js" */),
  "component---src-pages-product-strategy-js": () => import("./../../../src/pages/product-strategy.js" /* webpackChunkName: "component---src-pages-product-strategy-js" */),
  "component---src-pages-project-support-js": () => import("./../../../src/pages/project-support.js" /* webpackChunkName: "component---src-pages-project-support-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-servant-js": () => import("./../../../src/pages/servant.js" /* webpackChunkName: "component---src-pages-servant-js" */),
  "component---src-pages-shift-js": () => import("./../../../src/pages/shift.js" /* webpackChunkName: "component---src-pages-shift-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-software-project-estimation-js": () => import("./../../../src/pages/software-project-estimation.js" /* webpackChunkName: "component---src-pages-software-project-estimation-js" */),
  "component---src-pages-spoke-js": () => import("./../../../src/pages/spoke.js" /* webpackChunkName: "component---src-pages-spoke-js" */),
  "component---src-pages-telecom-software-development-js": () => import("./../../../src/pages/telecom-software-development.js" /* webpackChunkName: "component---src-pages-telecom-software-development-js" */),
  "component---src-pages-ui-ux-js": () => import("./../../../src/pages/ui-ux.js" /* webpackChunkName: "component---src-pages-ui-ux-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-pages-wristo-js": () => import("./../../../src/pages/wristo.js" /* webpackChunkName: "component---src-pages-wristo-js" */),
  "component---src-pages-youme-js": () => import("./../../../src/pages/youme.js" /* webpackChunkName: "component---src-pages-youme-js" */),
  "component---src-templates-portfolio-category-index-js": () => import("./../../../src/templates/portfolio_category/index.js" /* webpackChunkName: "component---src-templates-portfolio-category-index-js" */)
}

